import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";
import {WlFavoriteEvent} from "../events/WlFavorite";

export const FAVORITES_COOKIE_NAME: string = '_wl_favorites';

export class WlFavorite extends LitElement {
    private isSelected: boolean;
    private readonly listingId: string;
    private readonly FAVORITES_COOKIE_EXPIRATION_DAYS: number = 365;

    static get properties() {
        return {
            listingId: {type: String},
            isSelected: {type: Boolean},
        };
    }

    constructor() {
        super();
        this.listingId = '';
        this.isSelected = false;
    }

    connectedCallback() {
        super.connectedCallback();
        this.isSelected = this._getFavoritesFromCookie().includes(this.listingId);
    }

    static get styles() {
        return css`
            :host {
                max-width: var(--wl-favorite-width, ${pxToRem(48)});
                width: var(--wl-favorite-width, ${pxToRem(48)});
                height: var(--wl-favorite-height, ${pxToRem(48)});
            }

            .favorite {
                cursor: pointer;
                width: 100%;
                height: 100%;
                background-image: var(--wl-favorite-background-image, url('data:image/svg+xml;utf8,<svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M26.3926 8.0086C25.5808 8.70602 24.7833 9.55317 24 10.55C23.286 9.52489 22.5168 8.65808 21.6923 7.94961C21.0154 7.36806 20.3013 6.89321 19.55 6.52505C17.8833 5.70838 16.2 5.30005 14.5 5.30005C11.5333 5.30005 9.04167 6.30838 7.025 8.32505C5.00833 10.3417 4 12.85 4 15.85C4 19.6501 5.51667 23.2917 8.55 26.775C11.5833 30.2584 15.1667 33.9167 19.3 37.7501L21.95 40.2001C22.5167 40.7334 23.2 41 24 41C24.8 41 25.4833 40.7334 26.05 40.2001L28.7 37.7501C32.8333 33.9167 36.4167 30.2584 39.45 26.775C42.4833 23.2917 44 19.6501 44 15.85C44 12.85 42.9917 10.3417 40.975 8.32505C38.9583 6.30838 36.4667 5.30005 33.5 5.30005C31.7667 5.30005 30.1167 5.70838 28.55 6.52505C27.82 6.90558 27.1009 7.40009 26.3926 8.0086ZM26.3686 35.2362L24 37.426L21.6314 35.2362C21.6309 35.2357 21.6304 35.2352 21.6298 35.2347C17.5678 31.4675 14.0734 27.8971 11.1356 24.5235C8.5235 21.5238 7.42857 18.6534 7.42857 15.85C7.42857 13.7069 8.11187 12.0869 9.44937 10.7494C10.7948 9.40401 12.4019 8.72862 14.5 8.72862C15.6304 8.72862 16.8005 8.99586 18.0414 9.60387C19.0794 10.1125 20.1449 11.0138 21.1865 12.5095L23.8339 16.3109L26.6959 12.6683C27.9598 11.0598 29.1211 10.0938 30.1348 9.56535C31.222 8.99864 32.3294 8.72862 33.5 8.72862C35.5981 8.72862 37.2052 9.40401 38.5506 10.7494C39.8881 12.0869 40.5714 13.7069 40.5714 15.85C40.5714 18.6534 39.4765 21.5238 36.8644 24.5235C33.9266 27.8971 30.4322 31.4675 26.3702 35.2347C26.3696 35.2352 26.3691 35.2357 26.3686 35.2362Z" fill="black"/></svg>'));
                filter: var(--wl-favorite-background-color-filter, none);
                background-repeat: no-repeat;
            }
            
            .favorite.selected {
                background-image: var(--wl-favorite-background-image-selected, url('data:image/svg+xml;utf8,<svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.95 40.2L19.3 37.75C15.1667 33.9167 11.5833 30.2583 8.55 26.775C5.51667 23.2917 4 19.65 4 15.85C4 12.85 5.00833 10.3417 7.025 8.325C9.04167 6.30834 11.5333 5.3 14.5 5.3C16.2 5.3 17.8833 5.70834 19.55 6.525C21.2167 7.34167 22.7 8.68334 24 10.55C25.4667 8.68334 26.9833 7.34167 28.55 6.525C30.1167 5.70834 31.7667 5.3 33.5 5.3C36.4667 5.3 38.9583 6.30834 40.975 8.325C42.9917 10.3417 44 12.85 44 15.85C44 19.65 42.4833 23.2917 39.45 26.775C36.4167 30.2583 32.8333 33.9167 28.7 37.75L26.05 40.2C25.4833 40.7333 24.8 41 24 41C23.2 41 22.5167 40.7333 21.95 40.2Z" fill="#0A0A0A"/></svg>'));
                filter: var(--wl-favorite-selected-background-color-filter, none);
            }
        `
    }

    protected render() {
        const isSelectedClass: string = this.isSelected ? ' selected' : '';
        return html`
            <div class="favorite ${isSelectedClass}" @click="${this._clickOnFavorite}"></div>`;
    }

    private _clickOnFavorite(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        const favorites: string[] = this._getFavoritesFromCookie();
        if (this.isSelected) {
            this._setFavoritesCookie(favorites.filter((id: string) => id !== this.listingId));
        } else {
            favorites.push(this.listingId);
            this._setFavoritesCookie(favorites);
        }
        this.isSelected = !this.isSelected;
        this.dispatchEvent(new WlFavoriteEvent(this.listingId, this.isSelected));
    }

    private _setFavoritesCookie(ids: string[]) {
        const value: string = JSON.stringify(Array.from(new Set(ids)));
        const expirationTimeInMs: number = this.FAVORITES_COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000;
        const date: Date = new Date();
        date.setTime(date.getTime() + expirationTimeInMs);
        const expires: string = `expires=${date.toUTCString()}`;
        const htmlEncodedValue: string = encodeURIComponent(value);
        document.cookie = `${FAVORITES_COOKIE_NAME}=${htmlEncodedValue}; ${expires}; Path=/`;
    }

    private _getFavoritesFromCookie(): string[] {
        const cookieList: string[] = document.cookie.split(';');
        const cookie = cookieList.find(c => c.trim().startsWith(FAVORITES_COOKIE_NAME));
        if (cookie) {
            const value: string = cookie.trim().substring(FAVORITES_COOKIE_NAME.length + 1);
            try {
                return JSON.parse(decodeURIComponent(value));
            } catch (e) {
                return [];
            }
        }
        return [];
    }
}
