import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {AutocompleteButton} from "./autocompleteButton.component";
import {PropertyValues} from "@lit/reactive-element";
import {AutocompleteContent} from "./autocompleteContent.component";

const BUTTON_SLOT = "button";
const CONTENT_SLOT = "content";

export class WlAutoComplete extends LitElement {
    private open: boolean
    private button: AutocompleteButton | undefined
    private content: AutocompleteContent | undefined

    constructor() {
        super();
        this.open = false;
    }

    static get properties() {
        return {
            placeholder: {type: String},
            name: {type: String},
            open: {type: Boolean, reflect: true},
        };
    }

    static get styles() {
        return [css`
            :host {
                display: block;
                position: relative;
                font-size: var(--wl-control-font-size, ${pxToRem(18)});
            }

            :host([open]) .button {
                border-color: var(--wl-control-opened-border-color, #2A355A);
            }

            :host([open]) .content {
                display: flex;
                flex-direction: column;
            }
            
            .button {
                display: flex;
                align-items: center;
                cursor: pointer;
                border: 1px solid var(--wl-control-border-color, #8F939F);
                border-radius: var(--wl-control-border-radius, ${pxToRem(4)});
                height: var(--wl-control-height, ${pxToRem(41)});
                position: relative;
                font-size: var(--wl-control-font-size, ${pxToRem(14)});
                color: var(--wl-control-font-color, initial);
                font-weight: var(--wl-control-font-weight, initial);

                max-width: var(--wl-control-width, auto);
                width: var(--wl-control-width, auto);
                min-width: var(--wl-control-width, auto);
            }

            .content {
                display: none;
                border: var(--wl-control-border-size, ${pxToRem(1)}) solid var(--wl-dropdown-content-border-color, var(--wl-control-border-color, #8F939F));
                border-radius: var(--wl-control-border-radius, ${pxToRem(4)});
                max-height: var(--wl-dropdown-content-height, ${pxToRem(275)});
                position: absolute;

                top: calc(var(--wl-control-height) + var(--wl-control-border-size, ${pxToRem(1)}) + var(--wl-control-content-gap, ${pxToRem(10)}));
                right: var(--wl-dropdown-content-right, initial);
                z-index: 5;
                background-color: white;
                width: var(--wl-control-content-width, ${pxToRem(100)});
                max-width: var(--wl-control-content-width, ${pxToRem(100)});
                overflow-y: auto;
                box-shadow: var(--wl-dropdown-content-shadow, none);
            }
        `]
    }

    protected render(): unknown {
        return html`
            <div class="button" @click="${this._toggle}">
                <slot name="${BUTTON_SLOT}" @wl-change="${this._isSearching}" @slotchange="${this._initButton}"></slot>
            </div>
            <div class="content">
                <slot name="${CONTENT_SLOT}" @wl-change="${this._changed}" @slotchange="${this._initContent}" @wl-init="${this._changed}"></slot>
            </div>
        `;
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);
    }

    protected _initButton() {
        this.button = this.getElement<AutocompleteButton>(BUTTON_SLOT)
        if (this.button && this.content) {
            this.button.selectedOptions = this.content.selectedOptions
        }
    }

    protected _initContent() {
        this.content = this.getElement<AutocompleteContent>(CONTENT_SLOT)
        if (this.button && this.content) {
            this.button.selectedOptions = this.content.selectedOptions
        }
    }

    private getElement<Type>(slotName: string): Type | undefined {
        return (this.shadowRoot?.querySelector(`slot[name='${slotName}']`) as HTMLSlotElement)
            .assignedElements()
            .map(child => child as Type)
            .pop();
    }

    protected _isSearching() {
        if (this.button && this.content) {
            this.content.searchingText = this.button.value || ""
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("click", this._handleClickOutside.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener("click", this._handleClickOutside.bind(this));
    }

    private _handleClickOutside(event: Event) {
        const clickOutside = !event.composedPath().includes(this)
        if (clickOutside) {
            this._close()
        }
    }

    private _changed(event: WlSelected) {
        if (this.button && this.content) {
            this.button.selectedOptions = this.content.selectedOptions
        }
        this._close()
        this._syncButton()
    }

    protected _toggle() {
        this.open = !this.open
        this._syncButton()
    }

    private _close() {
        this.open = false
        this._syncButton()
    }

    private _syncButton() {
        if (this.button) {
            this.button.open = this.open
        }
    }
}
