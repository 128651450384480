import PageData from '../PageData';
import {PhoneTrackingResponse} from "../types/PhoneTrackingResponse";
import WhatsappService from "./WhatsappService";
import DateFormatter from "./DateFormatter";

export interface PhoneTrackingRequest {
    email: string;
    pageViewId: string;
    message?: string | null;
    rentStartDate?: string | null;
    rentEndDate?: string | null;
}

export default class LeadTrackingService {
    private readonly pageData: PageData;
    private readonly PHONE_AUTOMATIC_API_URI_ENDPOINT: string = '/adform/api/phone/automatic/';
    private readonly PHONE_MANUAL_API_URI_ENDPOINT: string = '/adform/api/phone/manual/';

    constructor(pageData: PageData) {
        this.pageData = pageData
    }

    public getWhatsappData = (
        section: number,
        message: string | null = '',
        rentStartDate: Date | null = null,
        rentEndDate: Date | null = null
    ) => new WhatsappService().getWhatsappData(
        section,
        this.pageData.chatsData,
        this.pageData.country,
        this.pageData.device,
        this.pageData.id,
        this.pageData.pageViewId,
        message,
        rentStartDate,
        rentEndDate
    )

    public getPhoneAutomaticData = async (
        id: string,
        message: string | null = '',
        rentStartDate: Date | null = null,
        rentEndDate: Date | null = null
    ): Promise<PhoneTrackingResponse> => {
        const email = localStorage.getItem("email");
        if (!email) return {data: null};
        const phoneTrackingRequest: PhoneTrackingRequest = {
            email: email,
            pageViewId: this.pageData.pageViewId,
            message: message
        };
        if (rentStartDate && rentEndDate) {
            phoneTrackingRequest.rentStartDate = this.formatDate(rentStartDate);
            phoneTrackingRequest.rentEndDate = this.formatDate(rentEndDate);
        }
        const request = await fetch(`${this.PHONE_AUTOMATIC_API_URI_ENDPOINT}${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(phoneTrackingRequest),
        });

        if (request.status !== 200) return {data: null};

        return request.json();
    }

    public getPhoneManualData = async (
        id: string,
        message: string | null = '',
        rentStartDate: Date | null = null,
        rentEndDate: Date | null = null
    ): Promise<PhoneTrackingResponse> => {
        const email = localStorage.getItem("email");
        if (!email) return {data: null};
        const phoneTrackingRequest: PhoneTrackingRequest = {
            email: email,
            pageViewId: this.pageData.pageViewId,
            message: message
        };
        if (rentStartDate && rentEndDate) {
            phoneTrackingRequest.rentStartDate = this.formatDate(rentStartDate);
            phoneTrackingRequest.rentEndDate = this.formatDate(rentEndDate);
        }
        const request = await fetch(`${this.PHONE_MANUAL_API_URI_ENDPOINT}${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(phoneTrackingRequest),
        });

        if (request.status !== 200) return {data: null};

        return request.json();
    }

    private formatDate(date?: Date | null) : string | null {
        return DateFormatter.formatDate(date);
    }

}
