import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";

export class WlShare extends LitElement {
    readonly title: string;
    private readonly text?: string;
    private readonly url: string;
    private readonly supportsNavigatorShare: boolean;

    static get properties() {
        return {
            title: {type: String},
            text: {type: String},
            url: {type: String},
        }
    }

    constructor() {
        super();
        this.title = "";
        this.text = "";
        this.url = "";
        this.supportsNavigatorShare = !!navigator.canShare && !!navigator.share;
    }

    static get styles() {
        return css`
            :host {
                max-width: var(--wl-share-width, ${pxToRem(30)});
                width: var(--wl-share-width, ${pxToRem(30)});
                height: var(--wl-share-height, ${pxToRem(30)});
            }

            .share {
                cursor: pointer;
                width: 100%;
                height: 100%;
                background-image: var(--wl-share-background-image, url('data:image/svg+xml;utf8,<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 18C9.41 18 10.233 17.642 10.857 17.07L17.117 20.647C17.0429 20.9254 17.0036 21.2119 17 21.5C17 23.43 18.57 25 20.5 25C22.43 25 24 23.43 24 21.5C24 19.57 22.43 18 20.5 18C19.59 18 18.767 18.358 18.143 18.93L11.883 15.353C11.946 15.106 11.986 14.851 11.991 14.585L18.142 11.07C18.767 11.642 19.59 12 20.5 12C22.43 12 24 10.43 24 8.5C24 6.57 22.43 5 20.5 5C18.57 5 17 6.57 17 8.5C17 8.796 17.048 9.078 17.117 9.353L11.433 12.602C11.1167 12.1113 10.6826 11.7077 10.1703 11.4278C9.658 11.148 9.08376 11.0009 8.5 11C6.57 11 5 12.57 5 14.5C5 16.43 6.57 18 8.5 18ZM20.5 20C21.327 20 22 20.673 22 21.5C22 22.327 21.327 23 20.5 23C19.673 23 19 22.327 19 21.5C19 20.673 19.673 20 20.5 20ZM20.5 7C21.327 7 22 7.673 22 8.5C22 9.327 21.327 10 20.5 10C19.673 10 19 9.327 19 8.5C19 7.673 19.673 7 20.5 7ZM8.5 13C9.327 13 10 13.673 10 14.5C10 15.327 9.327 16 8.5 16C7.673 16 7 15.327 7 14.5C7 13.673 7.673 13 8.5 13Z" fill="#333333"/></svg>'));
            }
        `
    }

    protected render() {
        if (!this.isValid()) return nothing;
        return html` <div class="share" @click="${this._clickOnShare}"></div>`;
    }

    private _clickOnShare(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.isValid()) return;
        navigator.share({
            title: this.title,
            text: this.text || this.title,
            url: this.url
        });
    }

    private isValid() {
        return this.supportsNavigatorShare && navigator.canShare({
            title: this.title,
            text: this.text || this.title,
            url: this.url
        }) && this.title && this.url;
    }
}
