import {css, html, LitElement, nothing, svg} from "lit";

const icons = {
    "filters": html`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1999 16.7346C19.6417 16.7346 19.9999 17.1001 19.9999 17.5509C19.9999 17.9517 19.7169 18.285 19.3437 18.3541L19.1999 18.3673H5.59981C5.15798 18.3673 4.7998 18.0018 4.7998 17.5509C4.7998 17.1502 5.08281 16.8169 5.45601 16.7478L5.59981 16.7346L19.1999 16.7346Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 17.551C11.9997 16.1985 10.9252 15.1021 9.59972 15.1021C8.27423 15.1021 7.19971 16.1985 7.19971 17.551C7.19971 18.9036 8.27423 20 9.59972 20C10.9252 20 11.9997 18.9036 11.9997 17.551ZM8.79973 17.551C8.79973 17.1002 9.1579 16.7347 9.59973 16.7347C10.0416 16.7347 10.3997 17.1002 10.3997 17.551C10.3997 18.0019 10.0416 18.3674 9.59973 18.3674C9.1579 18.3674 8.79973 18.0019 8.79973 17.551Z" fill="currentColor"/>
            <ellipse cx="9.59981" cy="17.5511" rx="0.800003" ry="0.816326" fill="white"/>
            <path d="M19.1999 5.63257C19.6417 5.63257 19.9999 5.99805 19.9999 6.44889C19.9999 6.84964 19.7169 7.18295 19.3437 7.25207L19.1999 7.26522L5.59981 7.26522C5.15798 7.26522 4.7998 6.89974 4.7998 6.44889C4.7998 6.04814 5.08281 5.71484 5.45601 5.64572L5.59981 5.63257L19.1999 5.63257Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 6.44898C11.9997 5.09644 10.9252 4 9.59972 4C8.27423 4 7.19971 5.09644 7.19971 6.44898C7.19971 7.80151 8.27423 8.89795 9.59972 8.89795C10.9252 8.89795 11.9997 7.80151 11.9997 6.44898ZM8.79973 6.44895C8.79973 5.99811 9.1579 5.63262 9.59973 5.63262C10.0416 5.63262 10.3997 5.99811 10.3997 6.44895C10.3997 6.89979 10.0416 7.26528 9.59973 7.26528C9.1579 7.26528 8.79973 6.89979 8.79973 6.44895Z" fill="currentColor"/>
            <ellipse cx="9.59981" cy="6.44889" rx="0.800003" ry="0.816326" fill="white"/>
            <path d="M4.80015 11.1837C4.35832 11.1837 4.00014 11.5492 4.00014 12C4.00014 12.4008 4.28314 12.7341 4.65635 12.8032L4.80015 12.8164L18.4002 12.8164C18.842 12.8164 19.2002 12.4509 19.2002 12C19.2002 11.5993 18.9172 11.266 18.544 11.1969L18.4002 11.1837L4.80015 11.1837Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 12C11.9998 10.6475 13.0743 9.55103 14.3998 9.55103C15.7253 9.55103 16.7998 10.6475 16.7998 12C16.7998 13.3525 15.7253 14.449 14.3998 14.449C13.0743 14.449 11.9998 13.3525 11.9998 12ZM15.1998 12C15.1998 11.5492 14.8416 11.1837 14.3998 11.1837C13.958 11.1837 13.5998 11.5492 13.5998 12C13.5998 12.4508 13.958 12.8163 14.3998 12.8163C14.8416 12.8163 15.1998 12.4508 15.1998 12Z" fill="currentColor"/>
            <ellipse cx="0.800003" cy="0.816325" rx="0.800003" ry="0.816325" transform="matrix(-1 0 0 1 15.1997 11.1837)" fill="white"/>
        </svg>
    `,
    "chevron-up": html`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.74948 8.66303C6.23197 8.24901 5.47682 8.33292 5.06281 8.85043C4.64879 9.36795 4.7327 10.1231 5.25021 10.5371L11.2502 15.3371C11.6885 15.6877 12.3112 15.6877 12.7495 15.3371L18.7495 10.5371C19.267 10.1231 19.3509 9.36795 18.9369 8.85043C18.5229 8.33292 17.7677 8.24901 17.2502 8.66303L11.9998 12.8633L6.74948 8.66303Z" fill="currentColor"/>
        </svg>`,
    "chevron-down": html`<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3172 14.9371C18.8347 15.3511 19.5898 15.2672 20.0038 14.7497C20.4179 14.2321 20.334 13.477 19.8164 13.063L13.8164 8.26299C13.3782 7.91238 12.7554 7.91238 12.3172 8.26299L6.31717 13.063C5.79965 13.477 5.71575 14.2321 6.12976 14.7497C6.54377 15.2672 7.29892 15.3511 7.81644 14.9371L13.0668 10.7368L18.3172 14.9371Z" fill="currentColor"/>
        </svg>`,
    "sorting": html`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6888 16.594C12.071 16.1412 12.768 16.0678 13.2457 16.4301L14.2153 17.1653L14.2153 4.64998C14.2153 4.07008 14.7112 3.59998 15.323 3.59998C15.9347 3.59998 16.4307 4.07008 16.4307 4.64998L16.4307 17.1653L17.4002 16.4301C17.878 16.0678 18.575 16.1412 18.9572 16.594C19.3393 17.0469 19.2619 17.7076 18.7842 18.0699L16.015 20.1699C15.6104 20.4767 15.0356 20.4767 14.631 20.1699L11.8618 18.0699C11.3841 17.7076 11.3066 17.0469 11.6888 16.594ZM7.56913 6.83463L6.59957 7.56989C6.12186 7.93215 5.4248 7.85873 5.04263 7.4059C4.66047 6.95308 4.73792 6.29232 5.21563 5.93006L7.98486 3.83006C8.38941 3.52328 8.96425 3.52328 9.3688 3.83006L12.138 5.93006C12.6157 6.29232 12.6932 6.95308 12.311 7.4059C11.9289 7.85873 11.2318 7.93215 10.7541 7.56989L9.78452 6.83463L9.78452 19.35C9.78452 19.9299 9.28859 20.4 8.67683 20.4C8.06507 20.4 7.56913 19.9299 7.56913 19.35L7.56913 6.83463Z" fill="#333133"/>
        </svg>`,
    "cross": html`<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9998 8.13302L12.1755 4.88829C12.4099 4.64889 12.7898 4.64889 13.0241 4.88829C13.2584 5.1277 13.2584 5.51585 13.0241 5.75526L9.84833 8.99999L13.0241 12.2447C13.2584 12.4841 13.2584 12.8723 13.0241 13.1117C12.7898 13.3511 12.4099 13.3511 12.1755 13.1117L8.9998 9.86695L5.82407 13.1117C5.58975 13.3511 5.20986 13.3511 4.97554 13.1117C4.74123 12.8723 4.74123 12.4841 4.97554 12.2447L8.15128 8.99999L4.97554 5.75526C4.74123 5.51585 4.74123 5.1277 4.97554 4.88829C5.20986 4.64889 5.58975 4.64889 5.82407 4.88829L8.9998 8.13302Z" fill="currentColor"/>
    </svg>`
}


function getUrl(name: string)  {
    if (name in icons) {
        // @ts-ignore
        return icons[name]
    }
    return '';
}

export class Icon extends LitElement {
    private icon: string;

    public static styles  = css`
        :host {
            display: inline-block;
            width: 100%;
            height: 100%;
            box-sizing: content-box !important;
        }
        
        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    `

    public static properties = {
        icon: {type: String}
    }

    constructor() {
        super()
        this.icon = ""
    }

    protected render() {
        return html`${getUrl(this.icon)}`;
    }
}